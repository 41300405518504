import DownloadIcon from "@mui/icons-material/Download";
import { Box, Divider, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { Link } from "react-router-dom";
import { textColor } from "../../utils/constants";
import sx from "./style";

export const PapersSection = ({ data, type }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  // Helper function to handle long titles on mobile
  const formatTitle = (title) => {
    if (isMobile && title.length > 80) {
      return title.substring(0, 80) + "...";
    }
    return title;
  };

  return (
    <Box sx={sx.container}>
      <Typography variant="h5" sx={sx.typeTitle}>
        {type}
      </Typography>
      <Box sx={sx.paperContainer}>
        {data.map((item, index) => (
          <Box sx={sx.bookData} key={index}>
            <Box sx={sx.titleContainer}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  lineHeight: isMobile ? 1.3 : 1.5,
                }}
              >
                {index + 1}.{" "}
                <Link
                  to={item.link}
                  style={{
                    wordBreak: isMobile ? "break-word" : "normal",
                    color: textColor,
                  }}
                >
                  {formatTitle(item.title)}
                </Link>
              </Typography>
              {item.downloadLink && (
                <a
                  href={item.downloadLink}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: isMobile ? "8px" : "10px",
                    flexShrink: 0,
                  }}
                >
                  <DownloadIcon
                    sx={{
                      color: textColor,
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                    }}
                  />
                </a>
              )}
            </Box>

            <Typography
              sx={{
                fontSize: isMobile ? "0.85rem" : "1rem",
                mt: 1,
                mb: 0.5,
                lineHeight: isMobile ? 1.3 : 1.5,
              }}
            >
              {item.authors}
            </Typography>

            <Typography
              sx={{
                fontSize: isMobile ? "0.8rem" : "0.9rem",
                fontStyle: "italic",
                color: textColor,
              }}
            >
              {item.journal}
            </Typography>
          </Box>
        ))}
      </Box>

      <Divider />
    </Box>
  );
};
