import { useMediaQuery } from "@mui/material";
import React from "react";
import { PublicationsData } from "../Data/data";
import { PapersSection } from "./PapersSection/layout";
import styles from "./Styles/sections.module.scss";

export default function Publications() {
  const isMobile = useMediaQuery("(max-width:600px)");

  // Filter publications by type
  const journalPapers = PublicationsData.filter(
    (data) => data.type === "journal"
  );
  const conferencePapers = PublicationsData.filter(
    (data) => data.type === "conference"
  );
  const bookChapters = PublicationsData.filter((data) => data.type === "book");

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Publications {!isMobile && "(Selected)"}</h1>
      </section>

      <div className={styles.contentWrapper}>
        <PapersSection type="Journal Papers" data={journalPapers} />
        <PapersSection type="Conference Papers" data={conferencePapers} />
        <PapersSection type="Book Chapters" data={bookChapters} />
      </div>
    </div>
  );
}
