// Education Data
const EducationData = [
  {
    degree: "Doctor of Philosophy (PhD)",
    // dessertationTitle:
    //   "Highly Miniaturized and Gain-Enhanced Ultra-wideband Antennas for Ground-Penetrating Radar Systems",
    school: "University of California, Davis",
    subject: "Electrical Engineering (EE)",
    year: "2023",
  },
  {
    degree: "Master of Science (MS)",
    school: "Utah State University",
    subject: "Electrical Engineering (EE)",
    year: "2017",
  },
  {
    degree: "Master of Science (MSc)",
    school: "Bangladesh University of Engineering and Technology (BUET)",
    subject:
      "Electrical and Electronic Engineering (EEE) (Minor Focus: Material Science)",
    year: "2015",
  },
  {
    degree: "Bachelor of Science (BSc)",
    school: "Bangladesh University of Engineering and Technology (BUET)",
    subject: "Electrical and Electronic Engineering (EEE)",
    year: "2012",
  },
];

// Honors and Awards Data
const HonorsAwardsData = [
  {
    others:
      "Awarded to final-year Ph.D. candidates with excellent academic standing",
    school: "University of California, Davis, USA",
    title: "UC Davis Dissertation Writing Fellowship",
    year: "2022",
  },
  {
    others:
      "Awarded by the ECE department to students who advance to candidacy within three years of starting their PhD degree to promote timely achievement of degree objectives.",
    school: "University of California, Davis, USA",
    title: "UC Davis Advancement-to-Candidacy (AC) fellowship",
    year: "2022",
  },
  {
    others: "",
    school: "University of California, Davis, USA",
    title: "Graduate Fellowship at University of California, Davis",
    year: "2018",
  },
  {
    others: "",
    school: "Utah State University, USA",
    title: "Graduate Assistantship at Utah State University",
    year: "2014",
  },
  {
    others: "Outstanding semester result",
    school: "BUET",
    title: "University Merit Scholarship",
    year: "2012",
  },
  {
    others:
      "ranked 14th out of more than 6000 top students in whole Bangladesh in BUET Admission Test- 2006 (The most competitive university entrance exam in that country)",
    school: "BUET",
    title: "University Admission Test Excellency Scholarship",
    year: "2007",
  },
  {
    others: "HSC results",
    school: "",
    title: "Dhaka Education Board Scholarship",
    year: "2007",
  },
  {
    others: "SSC results",
    school: "",
    title: "Jessore Education Board Scholarship",
    year: "2005",
  },
  {
    others: "Talent Pool",
    school: "",
    title: "Junior School Scholarship",
    year: "2002",
  },
  {
    others: "General",
    school: "",
    title: "Primary Education Scholarship",
    year: "1999",
  },
];

// Professional Experience Data
const ProfessionalExpData = [
  {
    organization: "Mimosa, Santa Clara, California, USA",
    others:
      "<p>• Design, development, characterization, and integration of massive MIMO array antennas</p>",
    place: "Santa Clara, California",
    title: "Antenna Design Engineer",
    year: "Sep 2023-Present",
  },
  {
    organization: "University of California, Davis",
    others:
      "<p>• Design, prototyping, and experimental investigation of electrically compact ultra-wideband and high gain antennas for ground-penetrating radar imaging system</p><p>• Millimeter-wave antenna design, measurement system setup, and experimentation (77-90 GHz)</p><p>• Courses Instructed (As Teaching Assistant)</p><p>- Control System I (EEC157A)</p><p>- Electromagnetics I (EEC130B)</p><p>- Electromagnetic Radiation and Antennas | (EEC133)</p>",
    place: "Davis, California",
    title: "Graduate Student Researcher & Teaching Assistant",
    year: "Sep 2018-2023",
  },
  {
    organization: "East West University",
    others:
      "<p>• Courses Instructed: Electrical Circuits &amp; Networks, Telecommunication Switching</p><p>&amp; Networks, Wireless Networks, and Object Oriented Programming</p>",
    place: "Dhaka, Bangladesh",
    title: "Lecturer",
    year: "Jan 2018-Aug 2018",
  },
  {
    organization: "i5 Technologies",
    others:
      "<p>• Design, optimization and fabrication of 4 × 4 and 8 × 8 multifunctional</p><p>reconfigurable phased array systems for 5G wireless access technologies</p>",
    place: "Logan, Utah",
    title: "Antenna/RF Design Engineer",
    year: "Mar 2017-Dec 2017",
  },
  {
    organization: "Utah State University",
    others:
      "<p>• Developed a 3-D novel multi-functional pattern reconfigurable dipole antenna system</p><p>• Designed and optimized dual polarized patch antenna array at 28 GHz</p><p>• First hand experience in Vector Network Analyzer (VNA) and anechoic chamber</p><p>• Investigated the system level performance of the designed antennas</p>",
    place: "Greater Salt Lake City Area",
    title: "Graduate Research Assistant",
    year: "May 2015-Dec 2016",
  },
  {
    organization: "Utah State University",
    others:
      "<p>• Assisted course teachers in grading undergraduate level DC Circuit course</p><p>• Taught undergraduate level AC Circuit course</p><p>• Taught and graded graduate level Electromagnetics-ll course</p>",
    place: "Greater Salt Lake City Area",
    title: "Graduate Teaching Assistant",
    year: "Sep 2014-Apr 2015",
  },
  {
    organization: "Bangladesh University of Business & Technology (BUBT)",
    others:
      "<p>• Theory classes conducted: Digital Logic Design, AC Circuits, Electromagnetics,</p><p> Telecommunication Theory, Digital Signal Processing-I</p><p>• Lab classes conducted: Digital Logic Design, Telecommunication Theory, Digital Signal Processing-I, Microwaves</p><p>• Supervised Thesis Title: Observation of Radiation and Bandwidth Performance of Different Types of Dipole Antennas</p>",
    place: "Dhaka, Bangladesh",
    title: "Lecturer",
    year: "Oct 2012-Jul 2014",
  },
];

// Publications Data
const PublicationsData = [
  {
    authors: "MA Hossain, I Bahceci, BA Cetiner",
    journal:
      "IEEE Transactions on Antennas and Propagation 65 (12), 6444-6452, Dec. 2017",
    link: "https://ieeexplore.ieee.org/abstract/document/8053786",
    title:
      "Parasitic layer-based radiation pattern reconfigurable antenna for 5G communications",
    type: "journal",
    year: "2017",
    downloadLink:
      "/assets/papers/Parasitic Layer-Based Radiation Pattern Reconfigurable Antenna for 5G Communications.pdf",
  },
  {
    authors: " A. Hossain and A. -V. Pham",
    journal:
      "IEEE Transactions on Antennas and Propagation, vol. 71, no. 12, pp. 9431-9439, Dec. 2023",
    link: "https://ieeexplore.ieee.org/document/10242339",
    title: "A Novel Gain-Enhanced Miniaturized and Lightweight Vivaldi Antenna",
    type: "journal",
    year: "2023",
    downloadLink:
      "/assets/papers/A Novel Gain-Enhanced Miniaturized and Lightweight Vivaldi Antenna.pdf",
  },
  {
    authors: "A. Hossain, S. Pancrazio, T. Kelley and A. -V. Pham",
    journal: "IEEE Antennas and Wireless Propagation Letters, 2025",
    link: "https://ieeexplore.ieee.org/document/10891804",
    title:
      "A Compact and Low-Profile High-Gain Multilayer Vivaldi Antenna Based on Gradient Metasurface Superstrates",
    type: "journal",
    year: "2025",
    downloadLink: "/assets/papers/A Compact and Low-Profile High-Gain.pdf",
  },

  {
    authors: "M Hossain, M Ferdous, SMH Chowdhury, M Abdul Matin",
    journal: "International Journal of Antennas and Propagation 2014",
    link: "https://www.hindawi.com/journals/ijap/2014/904374/abs/",
    title:
      "Novel dual band microstrip circular patch antennas loaded with ENG and MNG metamaterials",
    type: "journal",
    year: "2014",
  },

  {
    authors: "MA Hossain, MS Alam",
    journal: "Open Journal of Antennas and Propagation 6 (03), 36, 2018",
    link: "https://www.scirp.org/html/2-1290130_87363.htm?pagespeed=noscript",
    title:
      "Performance Evaluation of Rectangular Microstrip Patch Antennas Loaded with Plastic and Barium-Titanate Substrates at GSM 1800 MHz Band",
    type: "journal",
    year: "2018",
  },

  {
    authors: "S Ferdous, A Hossain, SMH Chowdhury, MRC Mahdy, M Abdul",
    journal: "IET Microwaves, Antennas & Propagation 7 (9), 768-776, June 2013",
    link: "https://digital-library.theiet.org/content/journals/10.1049/iet-map.2012.0582",
    title:
      "Reduced and conventional size multi-band circular patch antennas loaded with metamaterials",
    type: "journal",
    year: "2013",
  },

  {
    authors: "S. Wagner, A. Hossain, S. Pancrazio, and A. Pham",
    journal:
      "IET Microwaves, Antennas and Propagation, vol. 17, no. 4, pp. 1-16, Mar 2023",
    link: "https://ietresearch.onlinelibrary.wiley.com/doi/10.1049/mia2.12358",
    title:
      "System‐based specifications for better design of ground‐penetrating radar antennas",
    type: "journal",
    year: "2023",
  },

  {
    authors:
      "S. Pancrazio, S. Wagner, P. Nguyen, T. Kelley, A. Hossain, N. Tran, and A. -V. Pham",
    journal: "IEEE Transactions on Radar Systems, 2023",
    link: "https://ieeexplore.ieee.org/document/10164182",
    title:
      "Digital Pre-Distortion to Reduce Ringing in Ultrawideband Radar Systems",
    type: "journal",
    year: "2023",
  },

  {
    authors:
      "S. Pancrazio, T. Kelley, S. Wagner, N. Tran, A. Hossain and A. -V. Pham",
    journal:
      "IEEE Journal of Microwaves, vol. 5, no. 1, pp. 160-169, Jan. 2025",
    link: "https://ieeexplore.ieee.org/document/10812181",
    title:
      "Digital Pre-Distortion to Reduce Ringing in a Pulse Sharpening Non-Linear Transmission Line",
    type: "journal",
    year: "2025",
  },
  {
    authors:
      "Tyler Kelley, Stephen Pancrazio, Samuel Wagner, Ababil Hossain, Nhat Tran, and Anh-Vu Pham",
    journal: "IEEE Transactions on Radar Systems, 2025",
    link: "https://ieeexplore.ieee.org/document/10937905",
    title:
      "A Low-cost and Compact Software-Defined UWB Transmitter for Radar Utilizing a Non-Linear Transmission Line",
    type: "journal",
    year: "2025",
  },

  // Conference

  {
    authors: "A. Hossain, S. Wagner, S. Pancrazio and A. -V. Pham",
    journal:
      "IEEE International Symposium on Antennas and Propagation & USNC/URSI National Radio Science Meeting, Singapore, 2021, pp. 51-52",
    link: "https://ieeexplore.ieee.org/document/9704737",
    title:
      "A Compact Low-Cost and Lightweight 3-D Printed Horn Antenna for UWB Systems",
    type: "conference",
    year: "2021",
  },

  {
    authors: "A. Hossain, S. Wagner, S. Pancrazio and A. -V. Pham",
    journal:
      "IEEE International Symposium on Antennas and Propagation & USNC/URSI National Radio Science Meeting, Singapore, 2021, pp. 485-486",
    link: "https://ieeexplore.ieee.org/document/9704634",
    title:
      "An Electrically Smaller Ultra-Wideband Monopole Antenna for Ground Penetrating Radar Application",
    type: "conference",
    year: "2021",
  },

  // Book

  {
    authors: "MH Ababil, MF Saimoom, SMH Chowdhury, MRC Mahdy, MA Matin",
    journal:
      "Ultra-Wideband, Short-Pulse Electromagnetics 10, pp. 219-228, 2014",
    link: "https://link.springer.com/chapter/10.1007/978-1-4614-9500-0_20",
    title:
      "Highly directive epsilon negative metamaterial-loaded circular patch antenna for triple-band performance",
    type: "book",
    year: "2014",
  },
];

// Export all constants if using as a module
export {
  EducationData,
  HonorsAwardsData,
  ProfessionalExpData,
  PublicationsData,
};
