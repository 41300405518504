import { useMediaQuery } from "@mui/material";
import React from "react";
import { EducationData } from "../Data/data";
import { textColor } from "../utils/constants";
import styles from "./Styles/sections.module.scss";

export default function Education() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const fontStyles = {
    yearText: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 700,
      color: textColor,
      margin: 0,
    },
    schoolName: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      marginBottom: "4px",
      color: textColor,
      display: "block",
    },
    degree: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 500,
      fontStyle: "italic",
      fontSize: isMobile ? "0.95rem" : "1.05rem",
      marginBottom: "4px",
      display: "block",
    },
    subject: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 400,
      fontSize: isMobile ? "0.85rem" : "0.95rem",
      color: textColor,
      lineHeight: "1.4",
      display: "block",
    },
    dissertation: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 400,
      fontSize: isMobile ? "0.8rem" : "0.9rem",
      fontStyle: "italic",
      color: textColor,
      marginTop: "8px",
      display: "block",
    },
  };

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Education</h1>
      </section>
      {EducationData.map((item, index) => {
        return (
          <div className={styles.row} key={index}>
            <div className={styles.column1}>
              <div className={styles.info}>
                <p style={fontStyles.yearText}>{item.year}</p>
              </div>
            </div>
            <div className={styles.column2}>
              <div className={styles.info}>
                <span style={fontStyles.schoolName}>{item.school}</span>
                <span style={fontStyles.degree}>{item.degree}</span>
                <span style={fontStyles.subject}>{item.subject}</span>
                {item.dessertationTitle && (
                  <span style={fontStyles.dissertation}>
                    <strong>Dissertation:</strong> {item.dessertationTitle}
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
