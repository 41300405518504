import { useMediaQuery } from "@mui/material";
import React from "react";
import { HonorsAwardsData } from "../Data/data";
import { textColor } from "../utils/constants";
import styles from "./Styles/sections.module.scss";

export default function HonsAwards() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const fontStyles = {
    yearText: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 700,
      color: textColor,
      margin: 0,
    },
    title: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      marginBottom: "5px",
      color: textColor,
    },
    school: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 500,
      fontSize: isMobile ? "0.95rem" : "1.05rem",
      marginBottom: "3px",
    },
    others: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 400,
      fontSize: isMobile ? "0.8rem" : "0.9rem",
      color: textColor,
      marginTop: "5px",
      lineHeight: "1.4",
    },
    awardRow: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: isMobile ? "15px" : "20px",
      paddingBottom: isMobile ? "15px" : "20px",
      borderBottom: "1px solid #eee",
    },
    iconStyle: {
      color: textColor,
      fontSize: isMobile ? "18px" : "22px",
      marginRight: "10px",
      marginTop: "3px",
    },
  };

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Honors & Awards</h1>
      </section>

      {HonorsAwardsData?.map((item, index) => {
        return (
          <div className={styles.row} key={index}>
            <div className={styles.column1}>
              <div className={styles.info}>
                <p style={fontStyles.yearText}>{item?.year}</p>
              </div>
            </div>
            <div className={styles.column2}>
              <div className={styles.info}>
                <div style={fontStyles.title}>{item?.title}</div>
                {item?.school && (
                  <div style={fontStyles.school}>{item?.school}</div>
                )}
                {item?.others && (
                  <div style={fontStyles.others}>{item?.others}</div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
