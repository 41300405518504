import { useMediaQuery } from "@mui/material";
import React from "react";
import { ProfessionalExpData } from "../Data/data";
import { textColor } from "../utils/constants";
import styles from "./Styles/sections.module.scss";

export default function ProfessionalExperience() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const fontStyles = {
    yearText: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 700,
      color: textColor,
      margin: 0,
    },
    title: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      marginBottom: "5px",
      color: textColor,
    },
    organization: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 500,
      fontSize: isMobile ? "0.95rem" : "1.05rem",
      marginBottom: "3px",
    },
    place: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 400,
      fontSize: isMobile ? "0.9rem" : "1rem",
      fontStyle: "italic",
      color: textColor,
      marginBottom: "10px",
    },
    detailsWrapper: {
      marginTop: "10px",
      fontFamily: "'Roboto', sans-serif",
      fontSize: isMobile ? "0.85rem" : "0.95rem",
    },
  };

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Professional Experience</h1>
      </section>

      {ProfessionalExpData.map((item, index) => {
        return (
          <div className={styles.row} key={index}>
            <div className={styles.column1}>
              <div className={styles.info}>
                <p style={fontStyles.yearText}>{item.year}</p>
              </div>
            </div>
            <div className={styles.column2}>
              <div className={styles.info}>
                <div style={fontStyles.title}>{item.title}</div>
                <div style={fontStyles.organization}>{item.organization}</div>
                <div style={fontStyles.place}>{item.place}</div>
                <div
                  style={fontStyles.detailsWrapper}
                  dangerouslySetInnerHTML={{ __html: item.others }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
